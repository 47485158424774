<template>
    <div class="config">
        <div>优惠列表</div>
        <div style="overflow: hidden;overflow-y: scroll;height: 95%;padding: 0 3rem;">
            <div class="content-box" ref="list" style="padding: 0.1rem;">
                <div v-for="(item, index) in list" :key="index" class="listbox">
                    <div class="flex flex-child-center flex-justify-content-between listbox-line">
                        <div class="listbox-line-text" @click="policiesDetails(item)">
                            <span>{{ index + 1 }}. </span>
                            {{ item.title }}
                        </div>
                        <div class="listbox-line-text">
                            {{ item.createtime }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--弹出-->
    </div>
</template>

<script>

export default {
    name: "policiesList",
    data() {
        return {
            list: [],
            dialogFormVisible: false,
            // 所在省市
            editId: '',
            is_default: 0
        }
    },
    mounted() {
        this.onDetail();
    },
    created() {

    },
    watch: {
    },
    methods: {
        policiesDetails(item) {
            this.$router.push({
                name: 'policiesDetails',
                query: item
            })
        },
        onDetail() {
            this.$axios.post('api/kuerp/order/index/policies', {}).then(res => {
                this.list = res.data.data.list;
                console.log('this.list', this.list)
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.content-box {
    //height: 80%;

}

::v-deep {
    .el-dialog__header {
        padding: 0;
        text-align: center;
        height: 1rem;
    }
}

.listbox {
    border-radius: 0.04rem;
    font-size: 0.18rem;
    font-weight: 500;
    margin-bottom: 0.1rem;

    .listbox-line {
        margin-bottom: 0.1rem;

        img {
            width: 0.12rem;
            height: 0.12rem;
        }

        .listbox-line-text {
            display: flex;
            align-items: center;

            .moren {
                width: 0.69rem;
                height: 0.22rem;
                border-radius: 0.02rem;
                border: 0.01rem solid #4B83F0;
                font-size: 0.12rem;
                font-weight: 400;
                color: #4B83F0;
                line-height: 0.22rem;
                text-align: center;
                margin-left: 0.05rem;
            }
        }

        .listbox-line-click {
            font-weight: 400;
            color: #4B83F0;
        }

        span {
            display: block;
            width: 0.18rem;
            text-align: right;
            font-size: 0.18rem;
            font-weight: 400;
            color: #666666;
            margin-right: 0.3rem;
            text-align-last: justify;
        }
    }
}

.config {
    height: 100%;
    padding: 0.24rem 0 0.3rem 0.28rem;
    position: relative;

    ::v-deep {
        .el-textarea__inner {
            width: 3.44rem;
            font-size: 0.14rem;
            font-weight: 400;
            min-height: 0.94rem;
        }

        .el-input__inner {
            width: 3.44rem;
            font-size: 0.14rem;
            font-weight: 400;
        }

        .el-form-item__error {
            color: #CF7AE2;
        }

        .el-form-item.is-error .el-input__inner,
        .el-form-item.is-error .el-input__inner:focus,
        .el-form-item.is-error .el-textarea__inner,
        .el-form-item.is-error .el-textarea__inner:focus {
            border-color: #DCDFE6;
        }

        .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before,
        .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before {
            content: '';
        }
    }

    .dialog-footer {
        font-size: 0.16rem;
        font-weight: 600;
        color: #F9F4E4;
        text-align: center;

        ::v-deep {
            .el-button.is-round {
                width: 3.44rem;
                height: 0.55rem;
                background: #4B83F0;
                border-radius: 0.04rem !important;
            }
        }
    }

    >div:nth-child(1) {
        font-size: 0.18rem;
        font-weight: 400;
        color: #333333;
        padding-right: 0.28rem;
        padding-bottom: 0.1rem;
        border-bottom: 0.01rem solid #E4E4E4;
    }

    .addbox {
        width: 2.05rem;
        background: #F0F5FF;
        border-radius: 0.04rem;
        border: 0.01rem solid #4B83F0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.19rem 0;
        margin: 0.16rem 0 0.1rem 0;

        img {
            width: 0.16rem;
            height: 0.16rem;
        }

        div {
            font-size: 0.16rem;
            font-weight: 500;
            color: #333333;
            margin-left: 0.19rem;
        }
    }
}
</style>
